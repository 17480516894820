<template>
  <div class="input-select-input-box" v-bind:class="rootClassName">
    <span class="input-select-text">{{ text }}</span>
    <input
      type="text"
      :placeholder="textinputPlaceholder"
      autocomplete="on"
      class="input-select-textinput input"
    />
    <img :src="image_src" :alt="image_alt" class="input-select-arrow-down" />
  </div>
</template>

<script>
export default {
  name: 'InputSelect',
  props: {
    rootClassName: String,
    text: {
      type: String,
      default: 'City​',
    },
    textinputPlaceholder: {
      type: String,
      default: 'Tangerang',
    },
    image_src: {
      type: String,
      default: '/playground_assets/chevron-down.svg',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
  },
}
</script>

<style scoped>
.input-select-input-box {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.input-select-text {
  font-size: 18px;
  font-style: normal;
  font-family: Lora;
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.input-select-textinput {
  width: 100%;
  height: 64px;
  font-size: 16px;
  font-family: Montserrat;
  border:1px solid #1F252C;
}
.input-select-arrow-down {
  right: 20px;
  width: 24px;
  bottom: 20px;
  position: absolute;
  object-fit: cover;
}


</style>
